import React, { useState } from "react";
import "./App.scss";
import { Modal } from "./components/Modal/Modal";
import { Login } from "./components/Login/Login";
import { StepsManager } from "./components/StepManager/StepsManager";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const onAuthentication = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);
  };
  return (
    <div className="background">
      <Modal size="large">
        {isAuthenticated ? <StepsManager /> : <Login onAuthentication={onAuthentication} />}
      </Modal>
    </div>
  );
};

export default App;
