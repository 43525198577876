import React, { ChangeEvent, FormEvent, useRef, useState } from "react";
import "./AuthCode.scss";

interface AuthCodeProps {
  onAuth: (code: string) => void;
}

const AuthCode: React.FC<AuthCodeProps> = ({ onAuth }) => {
  const [code, setCode] = useState<string[]>(["", "", ""]);

  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const onChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === "") {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value !== "" && index < 2) {
        inputRefs[index + 1].current?.focus();
      }
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fullCode = code.join("");
    onAuth(fullCode);
  };

  return (
    <div className="auth-code-container">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          {code.map((value, index) => (
            <input
              key={index}
              ref={inputRefs[index]}
              type="text"
              value={value}
              onChange={(e) => onChange(e, index)}
              onFocus={() => inputRefs[index].current?.select()}
              maxLength={1}
              inputMode="tel"
              className="code-input"
            />
          ))}
        </div>
        <button type="submit">Entra</button>
      </form>
    </div>
  );
};

export default AuthCode;
