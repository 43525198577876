import React from "react";
import "./HoverImage.scss";

type Props = {
  text: string;
  textInsideImage: string;
  imagePath: string;
};
const HoverImage = (props: Props) => {
  const { text, textInsideImage, imagePath } = props;
  return (
    <div className="hover-container">
      <div className="hover-content">{text}</div>
      <div className="hover-image">
        <div className="text">{textInsideImage}</div>
        <img src={imagePath} alt="Hovered" />
      </div>
    </div>
  );
};

export default HoverImage;
