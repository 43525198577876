import React from "react";

export const Step1Content = () => {
  return (
    <div className="left-align">
      <p>Esatto. 666. Perchè sono il demonio.</p>
      <p>In questo passaggio troverai solo una lettera da leggere.</p>
      <i>Se sei con altri fallo da sola. Tutto il resto è visibile anche da altri.</i>

      <p>Allora eccoci qui.</p>

      <p>
        Facciamo qualche previsione della tua serata: immagino che fino all’ultimo avrai fatto la sostenuta,
        dicendo che non volevi festeggiare, forse ti sarai fatta anche qualche pianto, e poi alla fine
        qualcosa lo avrai sicuramente fatto perché, diciamocelo, non era vero che non volevi festeggiare.
      </p>

      <p>
        Sei una bella persona, è il mondo che è pieno di stronzi a partire da me. Magari a volte puoi essere
        difficile, ma sicuramente non ti sei mai meritata tante cose. Ma non parliamo degli stronzi. Visto che
        è il tuo compleanno, parliamo di me (che poi comunque sono uno stronzo, shit): per me in un certo
        senso sei stata la mia salvezza. In un momento della mia vita in cui, più che mai, non sapevo chi ero
        e dove stavo andando, in cui sentivo di vivere una doppia vita, destinata a durare per sempre, tu sei
        stata l’incarnazione della fiducia di cui avevo bisogno. Averti nella mia vita lo considero una
        fortuna. Vorrei tanto essere un amico migliore, uno che sa sempre come aiutarti, cosa dirti e che
        sappia farti ridere quando serve, ma alla fine sei sempre tu che aiuti me a essere una versione
        migliore di me stesso. Spero di riuscirlo a diventare un giorno.
      </p>

      <p>
        Betta, ormai un quarto di secolo della tua vita è andato. Basta concentrarci sugli stronzi: non te lo
        meriti di stare male per chi ti vuole male. Metti da parte Ansia e brinda ad un altro quarto di
        secolo, pieno di divertimento, cose giovanili e un po’ più di “e sti cazzi”.
      </p>

      <p> Poi, alla festa dei 50 facciamo il bilancio. </p>
      <p> Happy birthday!</p>

      <p>P.S. Quando torno dalla route sono in ferie, dobbiamo fare un sacco di cose pazze.</p>

      <p>
        <i>La tua Experience può iniziare...</i>
      </p>
    </div>
  );
};
