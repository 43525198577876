import { useEffect, useState } from "react";
import { Step1Content } from "./Step/Step1Content";
import { Step2Content } from "./Step/Step2Content";
import { Step3Content } from "./Step/Step3Content";
import { Step4Content } from "./Step/Step4Content";
import { Step5Content } from "./Step/Step5Content";
import { Step6Content } from "./Step/Step6Content";
import "./StepsManager.scss";
import env from "react-dotenv";

export const StepsManager = () => {
  const [step, setStep] = useState(1);
  const [canProceed, setCanProceed] = useState(true);
  const [timeLeft, setTimeLeft] = useState(0);

  const onClose = () => {
    window.open("https://doodles.google/doodle/googles-25th-birthday/", "_blank");
  };

  useEffect(() => {
    // Recupera il flag di completamento del timer dallo storage
    const timerCompleted = localStorage.getItem("timerCompleted") === "true";

    let timer: string | number | NodeJS.Timeout | undefined;

    if (step === 3 && !timerCompleted) {
      setCanProceed(false);
      const duration = env.TIMER_DURATION * 60;
      if (timeLeft === 0) {
        setTimeLeft(duration);
      }

      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setCanProceed(true);
            localStorage.setItem("timerCompleted", "true"); // Imposta il flag di completamento
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    } else {
      setCanProceed(true);
      setTimeLeft(0);
    }

    return () => clearInterval(timer);
  }, [step, timeLeft]);

  return (
    <>
      {step === 1 && <Step1Content />}
      {step === 2 && <Step2Content />}
      {step === 3 && <Step3Content timerDuration={env.TIMER_DURATION} />}
      {step === 4 && <Step4Content />}
      {step === 5 && <Step5Content />}
      {step === 6 && <Step6Content />}

      <div className="footerButtons">
        {step > 1 && step <= 6 && <button onClick={() => setStep(step - 1)}>Indietro</button>}
        {step < 6 && (
          <button onClick={() => setStep(step + 1)} disabled={!canProceed}>
            {canProceed
              ? "Avanti"
              : `Avanti (${Math.floor(timeLeft / 60)}:${(timeLeft % 60).toString().padStart(2, "0")})`}
          </button>
        )}
        {step === 6 && <button onClick={onClose}>VAI AL DOODLE</button>}
      </div>
    </>
  );
};
