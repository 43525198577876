import React from "react";
import "./Modal.scss";

type Props = {
  size: "small" | "medium" | "large";
  children: React.ReactNode;
};

export const Modal = (props: Props) => {
  const { size, children } = props;

  return (
    <div className="modal-overlay">
      <div className={`modal-content ${size}`}>{children}</div>
    </div>
  );
};
