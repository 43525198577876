import React from "react";

export const Step5Content = () => {
  const downloadCoupon = () => {
    const link = document.createElement("a");
    link.href = "/coupon.jpg";
    link.download = "Coupon.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <p>Qui trovi il coupon da scaricare per usufruire dell'offerta.</p>
      <p>Il coupon va attivano scansionando il QR Code col tuo telefono!</p>
      <p>
        <b>È importante, solo col tuo telefono!</b>
        <div>
          Così se lo vuoi scansionare lo scansioni ora verrai portata ad una pagina sul TUO telefono, e quindi
          potrai andare avanti.
        </div>
      </p>
      <div className="coupon-container">
        <img className="coupon" src="/coupon.png" alt="coupon" />
      </div>
      <button className="downloadButton" onClick={downloadCoupon}>
        Download
      </button>
    </>
  );
};
