import React from "react";

type Props = {
  timerDuration: number;
};
export const Step3Content = (props: Props) => {
  const { timerDuration } = props;

  const openPortofolio = () => {
    window.open("https://portfolio-1dg.pages.dev/");
  };

  return (
    <div className="left-align">
      <p>Ma ora veniamo a noi.</p>

      <p>Adesso devi collegarti al sito alla fine di questa card.</p>

      <p>
        È un qualcosa che nella realizzazione è fatto per ridere, ma in realtà ha un senso. Poi capirai che
        intendo.
      </p>

      <p>
        L’istruzione adesso è: apri il sito, esploralo TUTTO, quando hai finito la navigazione, hai esplorato
        ogni sezione, cliccato su ogni link... passa al prossimo bigliettino.
      </p>

      <p>
        Per una migliore user experience, e per impedirti di fare cazzate, ho messo un timer di{" "}
        {timerDuration} minuti, prima dei quali non potrai passare al prossimo step, così sono sicuro che con
        la tua ADHD non vai avanti prima di aver finito.
      </p>

      <div className="flex justify-center">
        <button className="portfolioButton" onClick={openPortofolio}>
          Apri il sito
        </button>
      </div>
    </div>
  );
};
