import React from "react";

export const Step4Content = () => {
  return (
    <div className="left-align">
      <p>
        Bene, oltre a farti una cosa simpatica, in realtà voglio spiegarti il doppio senso dietro questa cosa
        che ho fatto: voglio dirti che comprendo la tua ansia sul futuro, sul non riuscire a cominciare da
        qualche parte per il lavoro e tutto il resto che non è necessario esplicare qui.
      </p>

      <p> Voglio, da amico, fare la mia parte in questo:</p>

      <p>
        se lo vorrai fare, hai la mia completa disposizione per fare non un portofolio noioso cartaceo, ma un
        cazzo di sito web fico, con scritti i tuoi lavori, che racconti la tua tesi: io per quanto ho fatto
        finta di capirci qualcosa di brand strategy e queste cose nel sito portofolio, ovviamente non ci
        capisco niente. Ma una cosa l’ho capita ascoltando la tua tesi: hai una cazzo di visione di questa
        roba che sei sprecata a fare noiosi mockup o annunci social: devi trovare il modo di raccontare e
        vendere la tua visione. Non sarai già domani la super graphic designer del mondo impegnata sul sociale
        che lascerà un segno, ma si cammina un passo dopo l’altro, e io sono sicuro che ce la farai a
        realizzare quello che vuoi.
      </p>

      <p>
        Se pensi che fare un portfolio su un cazzo di sito web personale possa essere una cosa utile, sono
        felice di muoverli con te questi passi.
      </p>

      <p>
        PS: quello che hai visto è niente di più che un template già fatto che ho personalizzato, se lo vorrai
        fare lo disegni tu come lo vuoi con tutte le tue idee pazze, e lo facciamo!
      </p>
      <i>Che poi magari qualche div e qualche regola css ce li metti anche tu 😊</i>
    </div>
  );
};
