export const Step6Content = () => {
  return (
    <div className="text-left">
      <p>Bene, la tua experience giunge al termine.</p>

      <p>
        La chiusura del sito ti porterà in una schermata non casuale.
        <div>È giunto il momento di svelarti il mio feticismo per i Doodle di Google.</div>
        <div>Però non ne ho messo uno casuale. È il Doodle del 25° compleanno di Google.</div>
      </p>
      <p>Quindi:</p>
      <ul>
        <li>25 come i tuoi anni oggi</li>
        <li>
          Poi c'è anche il fatto che il Doodle secondo me è proprio fichissimo nel raccontare quello che fa un
          designer. Infatti ha tutti i loghi di Google, dal primo a quello attuale, e nonostante si possa
          vedere quanto ora risultino brutti quelli vecchi, mi piace vedere come il design sia migliorato e
          come negli anni, pur cambiando e maturando, sia rimasto sempre fedele a se stesso, nella sua
          iconicità e riconoscibilità. E sono sicuro che in questo ci sia una qualche metafora che tu saprai
          cogliere.
        </li>
      </ul>
      <p>
        P.S. Ti invito a notare che il font scelto per queste card non è un banale Arial, Times New Roman o
        Comic Sans, ma è un ricercatissimo <i>Humanist 521 Std Roman</i>
      </p>
      <i>Chissà cosa starò facendo ora, secondo me starò dormendo male pieno di dolori.</i>
      <p>See you at Rome, per un Agosto paxxerello con un amico di merda!</p>
    </div>
  );
};
