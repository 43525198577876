import React, { useState } from "react";
import "./Step2Content.scss";

interface MediaItem {
  type: "image" | "video";
  src: string;
  poster?: string; // Solo per i video
}

export const Step2Content = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % mediaItems.length);
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? mediaItems.length - 1 : prevIndex - 1));
  };

  const mediaItems: MediaItem[] = [
    { type: "video", src: "images/video.mp4" },
    { type: "image", src: "images/1.jpeg" },
    { type: "image", src: "images/2.jpeg" },
    { type: "image", src: "images/3.jpeg" },
    { type: "image", src: "images/4.jpeg" },
    { type: "image", src: "images/5.jpeg" },
    { type: "image", src: "images/6.jpeg" },
    { type: "image", src: "images/7.jpeg" },
    { type: "image", src: "images/8.jpeg" },
    { type: "image", src: "images/9.jpeg" },
    { type: "image", src: "images/10.jpeg" },
    { type: "image", src: "images/11.jpeg" },
    { type: "image", src: "images/12.jpeg" },
    { type: "image", src: "images/13.jpeg" },
  ];

  const { type, src, poster } = mediaItems[currentIndex];

  return (
    <>
      <p>
        <p>Iniziamo con del sano trash.</p>
        Guarda il video e scorri le immagini tramite le <b>freccette</b>.
        <p>
          <b>I pulsanti avanti e indietro servono per scorrere tra le card non tra le foto.</b>
        </p>
        <p>Non ho avuto tempo di pensare a una UX migliore.</p>
      </p>

      <div className="gallery">
        <button className="gallery-button prev" onClick={goToPrevious}>
          &lt;
        </button>
        <div className="gallery-content">
          {type === "image" ? (
            <img src={src} alt="Gallery" className="gallery-image" />
          ) : (
            <video src={src} poster={poster} className="gallery-video" controls autoPlay />
          )}
        </div>
        <button className="gallery-button next" onClick={goToNext}>
          &gt;
        </button>
      </div>
      <p>
        {currentIndex + 1}/{mediaItems.length}
      </p>
      <b>Vai avanti solo quando hai finito le foto in galleria.</b>
    </>
  );
};
