import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Confetti from "react-confetti";
import useCountDown from "react-countdown-hook";
import "./QrCodePage.scss";
import env from "react-dotenv";

export const QrCodePage = () => {
  const [complete, setComplete] = useState(false);
  const [started, setStarted] = useState(false);
  const [timeLeft, { start }] = useCountDown(10 * 1000, 1000);
  const [timeLeftBeforeWhatsapp, { start: startWhatsappCountdown }] = useCountDown(15 * 1000, 1000);
  const [startedWhatsappCountDown, setStartedWhaysappCountDown] = useState(false);

  useEffect(() => {
    if (!started) {
      start();
      setStarted(true);
    }
  }, [started, start]);

  useEffect(() => {
    if (started && timeLeft === 0) {
      setComplete(true);
      startWhatsappCountdown();
      setStartedWhaysappCountDown(true);
    }
  }, [started, timeLeft, startWhatsappCountdown, setStartedWhaysappCountDown]);

  useEffect(() => {
    if (startedWhatsappCountDown && timeLeftBeforeWhatsapp === 0) {
      const message = "Ciao Signor Programmatore Supremo, ho appena attivato il mio buono regalo!";
      const encodedMessage = encodeURIComponent(message);
      const url = `https://wa.me/${env.PHONE_NUMBER}?text=${encodedMessage}`;
      window.open(url, "_self");
    }
  }, [startedWhatsappCountDown, timeLeftBeforeWhatsapp]);

  return (
    <div className="explosion-container">
      {complete && <Confetti />}
      <AnimatePresence>
        <motion.h1
          key={timeLeft / 1000}
          exit={{ y: 75, opacity: 0, position: "absolute" }}
          initial={{ y: -150, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            ease: "easeOut",
            duration: 1,
          }}
        >
          {timeLeft > 0 ? (
            timeLeft / 1000
          ) : (
            <div className="text-center">
              <p>Complimenti Betta!</p>
              <p> Hai attivato il tuo buono!</p>
              <p>Tanti auguri!</p>
              <div className="fs-18">
                Ora mi stai per informare dell'avvenuta attivazione. Succederà tra{" "}
                {timeLeftBeforeWhatsapp / 1000}.
              </div>
            </div>
          )}
        </motion.h1>
      </AnimatePresence>
    </div>
  );
};
