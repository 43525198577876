import HoverImage from "../HoverImage/HoverImage";
import AuthCode from "../AuthCode/AuthCode";
import React, { useEffect, useState } from "react";
import env from "react-dotenv";
import "./Login.scss";
import { animated, useSprings } from "@react-spring/web";

const numKnives = 50; // Numero di teschi che cadono

const generateKnifeStyles = () => ({
  from: { top: "-10%", left: `${Math.random() * 100}%`, opacity: 1 },
  to: { top: "110%", opacity: 0 },
  config: { duration: 5000 }, // Durata dell'animazione in millisecondi
  delay: 0,
});

type Props = {
  onAuthentication: (isAuthenticated: boolean) => void;
};
export const Login = (props: Props) => {
  const { onAuthentication } = props;

  const [showError, setShowError] = useState(false);
  const [animationTrigger, setAnimationTrigger] = useState(0);

  const [knifeSprings, api] = useSprings(numKnives, () => generateKnifeStyles(), []);

  useEffect(() => {
    if (showError) {
      api.start(() => generateKnifeStyles());
    }
  }, [animationTrigger, showError, api]);

  const handleAuth = (code: string) => {
    if (code === env.CODE_TO_LOGIN) {
      onAuthentication(true);
    } else {
      setShowError(true);
      setTimeout(() => setShowError(false), 5000);
      setAnimationTrigger(animationTrigger + 1); // Cambia lo stato per riavviare le animazioni
      onAuthentication(false);
    }
  };

  return (
    <div className="login-container">
      {!showError && (
        <>
          <h2>Ciao Elisabetta!</h2>
          <h2>Tanti Auguri! 🎉🎉🎉</h2>
          <p>Ora voglio che ti concentri.</p>
          <p>Chiudi gli occhi.</p>
          <p>
            <i>Cazzo fai, poi come leggi?</i>
          </p>
          <div>No serio, torna con la mente al 18 luglio: era la sera prima di partire per il campo.</div>
          <div>
            Credo che nel giro di due ore tu abbia detto 750mila volta "non voglio partire per il campo".
          </div>
          <div>
            A un certo punto mi chiedevi quando partivo in route e hai detto "ah ma quindi non ci sei per il
            mio compleanno".
          </div>
          <p>
            Quella sera ho fatto il vago, ma in realtà tutta sta roba la stavo già preparando da tempo, perchè
            già ci avevo pensato da tempo che non ci stavo e mi dispiaceva un botto.
          </p>
          <p>
            <i>Giusto perchè sono un amico di merda!</i>
          </p>
          <p>Ecco, non ci sono fisicamente, ma ci sono con il cuore e con questa sorpresa.</p>
          <div>Questa è la tua personal experience. Dovrai seguire tutte le istruzioni riportate.</div>
          <div>Per iniziare, inserisci il codice segreto.</div>
          <p className="flex justify-center">
            Passa il mouse&nbsp;
            <HoverImage imagePath="/diavolo.jpg" textInsideImage="È proprio il mio numero" text="qui" />
            &nbsp;per avere un'indizio.
          </p>
          <AuthCode onAuth={handleAuth} />
        </>
      )}

      {showError && (
        <>
          <div className="knife-container">
            {knifeSprings.map((props, i) => (
              <animated.img key={i} src={`knife.png`} style={props} className="knife" alt="knife" />
            ))}
          </div>
          <div className="error-message">Codice Errato! Prova di nuovo.</div>
        </>
      )}
    </div>
  );
};
